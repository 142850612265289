* {
  box-sizing: border-box;
  /* font-display: swap; */
}

:root {
  --n100: #000000;
  --n200: #2c2c2c;
  --n300: #595959;
  --n400: #808080;
  --n500: #a6a6a6;
  --n600: #c4c4c4;
  --n700: #e0e0e0;

  --p100: hsl(160, 100%, 30%);
  --p200: hsl(160, 100%, 35%);
  --p300: hsl(160, 100%, 40%);
  --p400: hsl(160, 100%, 45%);
  --p500: hsl(160, 100%, 50%);
  --p600: hsl(160, 100%, 85%);
  --p700: hsl(160, 100%, 92%);

  --s100: hsl(345, 100%, 30%);
  --s200: hsl(340, 100%, 40%);
  --s300: hsl(340, 100%, 50%);
  --s400: hsl(340, 100%, 60%);
  --s500: hsl(340, 100%, 70%);
  --s600: hsl(340, 100%, 80%);
  --s700: hsl(340, 100%, 90%);

  --width-limit: 1280px;

  @media (max-width: 720px) {
    --font-xl: 32px;
    --font-lg: 26px;
    --font-md: 20px;
    --font-sm: 16px;
    --font-xs: 14px;
  }

  @media (min-width: 721px) {
    --font-xl: 36px;
    --font-lg: 30px;
    --font-md: 24px;
    --font-sm: 20px;
    --font-xs: 16px;
  }
}

a:hover {
	color: var(--s500);
	text-decoration:none;
}

a {
	color: var(--p500);
	text-decoration:none;
}

img {
  box-sizing: content-box;
}

ul {
  list-style-type: none;
}

body {
  margin: 0;
  font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

section {
  text-align: center;
}

ul.list-inline {
  display: flex;
  padding-inline-start: 0;
  list-style: none;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.no-shrink {
  flex-shrink: 0;
}

h1,.h1 {
  font-size: 39px
}

h2,.h2 {
  font-size: 32px
}

h3,.h3 {
  font-size: 26px
}

h4,.h4 {
  font-size: 19px
}

h5,.h5 {
  font-size: 15px
}

h6,.h6 {
  font-size: 13px
}

@media (min-width: 900px) {
  .mobile {
    display: none;
  }
}