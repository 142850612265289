* {
  box-sizing: border-box;
}

:root {
  --n100: #000;
  --n200: #2c2c2c;
  --n300: #595959;
  --n400: gray;
  --n500: #a6a6a6;
  --n600: #c4c4c4;
  --n700: #e0e0e0;
  --p100: #096;
  --p200: #00b377;
  --p300: #0c8;
  --p400: #00e699;
  --p500: #0fa;
  --p600: #b3ffe6;
  --p700: #d6fff1;
  --s100: #990026;
  --s200: #c04;
  --s300: #f05;
  --s400: #f37;
  --s500: #f69;
  --s600: #f9b;
  --s700: #fcd;
  --width-limit: 1280px;

  @media (width <= 720px) {
    & {
      --font-xl: 32px;
      --font-lg: 26px;
      --font-md: 20px;
      --font-sm: 16px;
      --font-xs: 14px;
    }
  }

  @media (width >= 721px) {
    & {
      --font-xl: 36px;
      --font-lg: 30px;
      --font-md: 24px;
      --font-sm: 20px;
      --font-xs: 16px;
    }
  }
}

a:hover {
  color: var(--s500);
  text-decoration: none;
}

a {
  color: var(--p500);
  text-decoration: none;
}

img {
  box-sizing: content-box;
}

ul {
  list-style-type: none;
}

body {
  margin: 0;
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
}

section {
  text-align: center;
}

ul.list-inline {
  padding-inline-start: 0;
  list-style: none;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.no-shrink {
  flex-shrink: 0;
}

h1, .h1 {
  font-size: 39px;
}

h2, .h2 {
  font-size: 32px;
}

h3, .h3 {
  font-size: 26px;
}

h4, .h4 {
  font-size: 19px;
}

h5, .h5 {
  font-size: 15px;
}

h6, .h6 {
  font-size: 13px;
}

@media (width >= 900px) {
  .mobile {
    display: none;
  }
}
/*# sourceMappingURL=base.e6c1aac3.css.map */
